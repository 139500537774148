import { Menu, Image, Loader } from 'semantic-ui-react'
import Link from 'next/link'

import useAuthState from 'lib/hooks/useAuthState'
import NavLink from 'lib/components/NavLink'

import UserButton from './UserButton'

const Nav = ({ isMobile = false, sidebarOnHide = () => {} }) => {
  const [user, loading, error] = useAuthState()

  const hideOnClick = () => {
    if (isMobile) sidebarOnHide()
  }

  if (loading) {
    return <Loader active />
  }

  if (error) {
    console.error('Nav.tsx useAuthState error', error)
    return null
  }

  return (
    <>
      <Link href="/" passHref>
        <Menu.Item
          as="a"
          onClick={hideOnClick}
          css={{ display: 'flex !important', alignItems: 'center' }}
        >
          <Image
            src="/static/logo/white.svg"
            style={{ height: '32px', width: '32px' }}
          />
          Recall Monitor
        </Menu.Item>
      </Link>
      <NavLink href="/recalls" passHref loose>
        <Menu.Item as="a" onClick={hideOnClick}>
          Recent Recalls
        </Menu.Item>
      </NavLink>
      <NavLink href="/search" passHref>
        <Menu.Item as="a" onClick={hideOnClick}>
          Search Recalls
        </Menu.Item>
      </NavLink>
      {user && (
        <>
          <NavLink href="/myalerts" passHref>
            <Menu.Item as="a" onClick={hideOnClick}>
              My Alerts
            </Menu.Item>
          </NavLink>
        </>
      )}
      <NavLink href="/covid19" passHref>
        <Menu.Item as="a" onClick={hideOnClick}>
          COVID-19 Recalls
        </Menu.Item>
      </NavLink>
      <NavLink href="/pricing" passHref>
        <Menu.Item as="a" onClick={hideOnClick}>
          Pricing
        </Menu.Item>
      </NavLink>
      <Menu.Item position={isMobile ? null : 'right'}>
        <UserButton onClick={hideOnClick} />
      </Menu.Item>
    </>
  )
}

export default Nav
