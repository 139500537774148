import { createStore, combineReducers } from 'redux'

import search from './search'
import barcode from './barcode'
import vehicle from './vehicle'

const getStore = () =>
  createStore(
    combineReducers({
      search,
      barcode,
      vehicle,
    }),
    undefined,
    // @ts-ignore
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : x => x,
  )

export default getStore
