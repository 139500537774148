import React from 'react'
import { Header, List } from 'semantic-ui-react'
import Link from 'next/link'

const About = ({}) => {
  return (
    <>
      <Header inverted as="h4" content="About" />
      <List link inverted>
        <List.Item as="a" href="mailto:contact@recallmonitor.ca">
          Contact Us
        </List.Item>
        <Link href="/faq" passHref>
          <List.Item as="a">FAQ</List.Item>
        </Link>
        <Link href="/terms" passHref>
          <List.Item as="a">Terms</List.Item>
        </Link>
        <Link href="/privacy" passHref>
          <List.Item as="a">Privacy</List.Item>
        </Link>
      </List>
    </>
  )
}

export default About
