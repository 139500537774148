const vehicle = (
  state = {
    make: null,
    model: null,
    year: null,
    results: null,
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case 'vehicle/setMake':
      return {
        ...state,
        make: action.payload,
        models: [],
        modelsLoading: false,
        model: null,
      }
    case 'vehicle/setModel':
      return {
        ...state,
        model: action.payload,
      }
    case 'vehicle/setYear':
      return {
        ...state,
        year: action.payload,
      }
    case 'vehicle/results':
      return {
        ...state,
        results: action.payload,
      }
    case 'vehicle/setLoading':
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default vehicle
