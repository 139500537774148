import React from 'react'
import Head from 'next/head'

const SEO = ({
  title = 'Recall Monitor',
  canonical,
  description,
  type = 'website',
  image = 'https://recallmonitor.ca/static/logo/logo.png',
}) => (
  <Head>
    <meta property="og:site_name" content="Recall Monitor" key="og:site_name" />
    <meta property="og:type" content={type} key="og:type" />
    <meta
      property="twitter:site"
      content="@RecallMonitorCA"
      key="twitter:site"
    />
    <meta
      property="twitter:card"
      content="summary_large_image"
      key="twitter:card"
    />

    <title key="title">{title}</title>
    <meta property="og:title" content={title} key="og:title" />

    {canonical && (
      <>
        <link rel="canonical" href={canonical} key="canonical" />
        <meta property="og:url" content={canonical} key="og:url" />
      </>
    )}

    {description && (
      <>
        <meta name="description" content={description} key="description" />
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
      </>
    )}

    <meta property="og:image" content={image} key="og:image" />
  </Head>
)

export default SEO
