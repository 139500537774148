import React from 'react'
import { Header, List, Icon } from 'semantic-ui-react'

const Social = ({}) => {
  return (
    <>
      <Header inverted as="h4" content="Social" />
      <List link inverted horizontal>
        <List.Item
          as="a"
          href="https://www.facebook.com/RecallMonitorCA"
          target="_blank"
          rel="noreferer noopener"
        >
          <Icon link name="facebook f" size="large" />
        </List.Item>
        <List.Item
          as="a"
          href="https://twitter.com/RecallMonitorCA"
          target="_blank"
          rel="noreferer noopener"
        >
          <Icon link name="twitter" size="large" />
        </List.Item>
        <List.Item
          as="a"
          href="https://www.pinterest.ca/RecallMonitorCA/"
          target="_blank"
          rel="noreferer noopener"
        >
          <Icon link name="pinterest" size="large" />
        </List.Item>
      </List>
    </>
  )
}

export default Social
