import { useContext } from 'react'

import FirebaseContext from 'lib/FirebaseContext'

/**
 * @returns {firebase.app.App} firebase
 */
const useFirebase = () => {
  const firebase = useContext(FirebaseContext)

  return firebase
}

export default useFirebase
