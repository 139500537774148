import React from 'react'
import { Segment, Container, Grid } from 'semantic-ui-react'

import About from './About'
import Social from './Social'
import Why from './Why'
import AboutText from './AboutText'

const Footer = ({}) => {
  return (
    <Segment inverted vertical style={{ padding: '3rem 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row centered>
            <Grid.Column
              css={{
                display: 'flex !important',
                justifyContent: 'space-around !important',
              }}
              width={10}
            >
              <div>
                <About />
              </div>
              <div>
                <Why />
              </div>
              <div>
                <Social />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={10}>
              <AboutText />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  )
}

export default Footer
