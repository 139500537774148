import React from 'react'
import App from 'next/app'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import Head from 'next/head'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
// import 'firebase/functions'
// import 'firebase/performance'
// import 'firebase/storage'

import FirebaseContext from 'lib/FirebaseContext'
import TheApp from 'lib/pages/App'
import SEO from 'lib/components/SEO'

import createStore from 'lib/store'
const store = createStore()

import Router from 'next/router'

Router.events.on('routeChangeComplete', url => {
  window.gtag && window.gtag('config', 'UA-212741-14', { page_path: url })
})

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://b8bc5f26b34d46f6948c40ad99a619bd@o390341.ingest.sentry.io/5233135',
    release: process.env.BUILD_ID,
    environment: 'production',
  })
}

import { MediaContextProvider } from 'lib/MediaContext'

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props

    return (
      <MediaContextProvider>
        <Provider store={store}>
          <FirebaseContext.Provider
            value={
              firebase.apps.length
                ? firebase.apps[0]
                : firebase.initializeApp({
                    apiKey: 'AIzaSyCv6klZic1n6U7EmY2w0PbS7GuQ3PUIZcI',
                    authDomain: 'recallalerts-95fc4.firebaseapp.com',
                    databaseURL: 'https://recallalerts-95fc4.firebaseio.com',
                    projectId: 'recallalerts-95fc4',
                    storageBucket: 'recallalerts-95fc4.appspot.com',
                    messagingSenderId: '335105441538',
                    appId: '1:335105441538:web:29a992ce37968ccc',
                  })
            }
          >
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta name="buildId" content={process.env.BUILD_ID} />
            </Head>
            <SEO />

            <TheApp Component={Component} pageProps={pageProps} />
          </FirebaseContext.Provider>
        </Provider>
      </MediaContextProvider>
    )
  }
}

export default MyApp
