import { useState, useEffect } from 'react'
import firebase from 'firebase'

import useFirebase from './useFirebase'

const useAuthState = () => {
  const firebase = useFirebase()
  const [state, setState] = useState<{
    loading: boolean
    auth: firebase.User
    error: firebase.auth.Error | null
  }>({
    loading: true,
    auth: null,
    error: null,
  })

  useEffect(() => {
    const unregister = firebase.auth().onAuthStateChanged(
      auth => {
        setState({
          loading: false,
          auth,
          error: null,
        })
      },
      error => {
        console.log('auth error', error)
        setState({
          loading: false,
          auth: null,
          error,
        })
      },
    )

    return () => unregister()
  }, [])

  const update = async () => {
    if (!state.auth) return
    await state.auth.reload()
    setState(s => ({ ...s, auth: firebase.auth().currentUser }))
  }

  return [state.auth, state.loading, state.error, update] as const
}

export default useAuthState
