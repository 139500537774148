import React from 'react'
import { Header, Icon, Image } from 'semantic-ui-react'

import ExternalLink from 'lib/components/ExternalLink'

const AboutText = ({}) => {
  return (
    <>
      <Header as="h4" inverted css={{ display: 'flex' }}>
        <Image
          src="/static/logo/white.svg"
          style={{
            width: '20px',
            height: '20px',
            margin: 0,
          }}
        />
        Recall Monitor
      </Header>
      <p>
        Information presented from Recalls and Safety Alerts dataset, provided
        by the Government of Canada through the{' '}
        <ExternalLink href="https://open.canada.ca/en">
          Open Government
        </ExternalLink>{' '}
        initiative and is subject to the{' '}
        <ExternalLink href="https://open.canada.ca/en/open-government-licence-canada">
          Open Government License
        </ExternalLink>
        .
      </p>
      <p>
        You can access this data free of charge via Recall Monitor, or through
        the{' '}
        <ExternalLink href="http://www.healthycanadians.gc.ca/recall-alert-rappel-avis/index-eng.php">
          Healthy Canadians
        </ExternalLink>{' '}
        website.
      </p>
      <p>
        Get personalized SMS or Email notifications about recall alerts that
        matter by subscribing to Recall Monitor.
      </p>
      <p>
        Recall Monitor is not endorsed by, or is an official product of the
        Government of Canada.
      </p>
      <p>
        Made with <Icon fitted name="heart" color="red" /> in Vancouver, BC
      </p>
      <p>© Mad Filaments Software Inc.</p>
    </>
  )
}

export default AboutText
