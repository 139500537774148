const barcode = (
  state = {
    loading: false,
    result: null,
    code: '',
  },
  action,
) => {
  switch (action.type) {
    case 'barcode/load':
      return {
        ...state,
        loading: true,
      }
    case 'barcode/found':
      return {
        ...state,
        result: action.payload,
        loading: false,
      }
    case 'barcode/404':
      return {
        ...state,
        result: false,
        loading: false,
      }
    case 'barcode/code':
      return {
        ...state,
        code: action.payload,
      }
    default:
      return state
  }
}

export default barcode
