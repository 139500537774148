import React from 'react'
import { Sidebar } from 'semantic-ui-react'
import { ToastProvider } from 'react-toast-notifications'
import { ReactQueryDevtools } from 'react-query-devtools'

import Header from 'lib/sections/Header'
import Footer from 'lib/sections/Footer'

const App = ({ Component, pageProps }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false)

  return (
    <>
      <ToastProvider placement="top-right" autoDismiss>
        <ReactQueryDevtools />
        <Header
          sidebarOpen={sidebarOpen}
          sidebarOnHide={() => setSidebarOpen(false)}
          sidebarToggle={() => setSidebarOpen(true)}
        />
        <Sidebar.Pushable css={{ background: 'white' }}>
          <Sidebar.Pusher
            dimmed={sidebarOpen}
            css={{
              marginTop: 60,
            }}
          >
            <Component {...pageProps} />
            <Footer />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </ToastProvider>
    </>
  )
}

export default App
