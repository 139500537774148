import React from 'react'
import { Header, List } from 'semantic-ui-react'
import Link from 'next/link'

const Why = ({}) => {
  return (
    <>
      <Header inverted as="h4" content="Why subscribe?" />
      <List link inverted>
        <Link href="/why/allergies" passHref>
          <List.Item as="a">Allergies</List.Item>
        </Link>
        <Link href="/why/microbiological" passHref>
          <List.Item as="a">Food poisoning</List.Item>
        </Link>
        <Link href="/why/children" passHref>
          <List.Item as="a">Children & Infants</List.Item>
        </Link>
        <Link href="/why/drugs" passHref>
          <List.Item as="a">Medicine & Drugs</List.Item>
        </Link>
        <Link href="/why/vehicles" passHref>
          <List.Item as="a">Vehicles</List.Item>
        </Link>
      </List>
    </>
  )
}

export default Why
