import React from 'react'
import { Button } from 'semantic-ui-react'
import Link from 'next/link'
import Router from 'next/router'

import useFirebase from 'lib/hooks/useFirebase'
import useAuthState from 'lib/hooks/useAuthState'

const UserButton = ({ onClick }) => {
  const firebase = useFirebase()
  const [user, loading, error] = useAuthState()

  if (error) return null

  if (loading)
    return (
      <Button loading inverted>
        Loading
      </Button>
    )

  return (
    <>
      <Link href={user ? '/user' : '/signup'} passHref>
        <Button as="a" inverted onClick={onClick}>
          {user ? 'Your Settings' : 'Sign Up'}
        </Button>
      </Link>
      {user ? (
        <Button
          inverted
          basic
          onClick={() => {
            firebase.auth().signOut()
            Router.push('/')
            onClick()
          }}
          style={{
            marginLeft: '0.5rem',
          }}
        >
          Logout
        </Button>
      ) : (
        <Link href="/login" passHref>
          <Button
            inverted
            basic
            as="a"
            style={{
              marginLeft: '0.5rem',
            }}
            onClick={onClick}
          >
            Login
          </Button>
        </Link>
      )}
    </>
  )
}

export default UserButton
