const search = (
  state = {
    loading: false,
    results: null,
    count: null,
    text: '',
    cat: 0,
    page: 1,
    error: false,
  },
  action,
) => {
  switch (action.type) {
    case 'search/load':
      return {
        ...state,
        loading: true,
        error: false,
      }
    case 'search/success':
      return {
        ...state,
        results: action.payload.results,
        count: action.payload.results_count,
        loading: false,
        error: false,
      }
    case 'search/error':
      return {
        ...state,
        error: true,
        loading: false,
      }
    case 'search/text':
      return {
        ...state,
        text: action.payload,
      }
    case 'search/cat':
      return {
        ...state,
        cat: action.payload,
      }
    case 'search/page':
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state
  }
}

export default search
