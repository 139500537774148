import { Menu, Container, Sidebar, Icon, Image } from 'semantic-ui-react'

import MediaBreakpointLG from 'lib/components/MediaBreakpointLG'

import Nav from './Nav'

const Header = ({ sidebarOpen, sidebarOnHide, sidebarToggle }) => {
  return (
    <>
      <MediaBreakpointLG
        lessThan={
          <>
            <Sidebar
              as={Menu}
              animation="push"
              inverted
              onHide={sidebarOnHide}
              vertical
              visible={sidebarOpen}
            >
              <Nav isMobile sidebarOnHide={sidebarOnHide} />
            </Sidebar>

            <Menu inverted size="large" fixed="top" style={{ height: '60px' }}>
              <Menu.Item onClick={sidebarOpen ? null : sidebarToggle}>
                <Icon name="sidebar" />
              </Menu.Item>
              <Menu.Item css={{ display: 'flex', fontSize: '1em' }}>
                <Image
                  src="/static/logo/white.svg"
                  style={{ width: '20px', height: '20px' }}
                />
                Recall Monitor
              </Menu.Item>
            </Menu>
          </>
        }
        greaterOrEqual={
          <Menu fixed="top" size="large" inverted style={{ height: '60px' }}>
            <Container>
              <Nav />
            </Container>
          </Menu>
        }
      />
    </>
  )
}

export default Header
