import React, { Children } from 'react'
import Link from 'next/link'
import { withRouter } from 'next/router'

const NavLink = ({ router, children, as, href, loose, ...rest }) => {
  let active = router.asPath === href || router.asPath === as
  if (loose) active = router.asPath.indexOf(href) > -1

  return (
    <Link {...rest} href={href} as={as}>
      {React.cloneElement(Children.only(children), {
        className: active ? 'active' : null,
      })}
    </Link>
  )
}

export default withRouter(NavLink)
